export class ResponseMsgToClient
{
    
    MFAToken!:string;
    TrustDeviceInfo!:string;
    JwtToken!: JwtToken;
    //AccessToken!:string;
    RedirectedFrom!:string;
    TrustDeviceExpirationDate!:string;
}

export class JwtToken
{
    access_token!:string;
    token_type!:string;
    expires_in!:number;
    emailaddress!:string;
    issued!:string;
    expires!:string;


}