import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationLoaderService {
  use(lang: string) {
    throw new Error('Method not implemented.');
  }

  constructor() { }
}
