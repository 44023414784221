// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  baseAuthAPIUrl: 'https://auth.r2.rpost.net',
  azureSSOClientId: 'feebd1bf-fca0-4e38-8ab9-896cbcc5946f',
  scopeId: 'deba313c-7939-465c-babe-4c4f29a771dd',
  googleSSOClientId: '733799545702-6181gbtttdl84g8k6uj792mfqu1ukuho.apps.googleusercontent.com',
  googleProvider: 'Google',
  azureADProvider: 'AzureAD',
  rcapClientId: '89278687-d54e-4321-81af-9e0d5f2f8cda'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
