
export class AzureTokenResponseModel
{
    accesstoken!: string;
    username!: string;
    useremail!: string;
    idtoken!:string;
    refreshtoken!:string;
    ssotype!:string;
}

