import { Injectable } from '@angular/core';
//import { IAppUser, IBrandHF, IBrandContent } from './../auth/iapp-user';

@Injectable({
  providedIn: 'root'
})
export class UserSessionService {

  constructor() {}
  
  setLanguages(list: any) {
    const key = 'language';
    localStorage.setItem(key, JSON.stringify(list));
  }
  setAppLanguage(lang: string) {
    localStorage.setItem('appLang', lang);
  }
  
  getLanguages() {
    const key = 'language';
    if (localStorage.getItem(key) !== undefined && localStorage.getItem(key) !== null) {
      return JSON.parse(localStorage.getItem(key)!);
    }  else {
      return null;
    }
  }
  getAppLanguage() {
    const val = localStorage.getItem('appLang');
    if (val !== undefined && val !== null) {
      return val;
    } else {
      return 'en-us';
    }
  }
  
  
}
