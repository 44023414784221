<main class="login_wrapper my3" style="height: 100vh; background: aliceblue;">
    <div id="divProcessing" class="processing text-center" style="display: none;justify-content: center;align-items: center;width: 100%;position: relative;">
        <img src="../assets/images/orange-loader.gif"
              alt="Processing..."  style="position: absolute;top: 221px;z-index: 999; width:175px;"
           />
    </div>
    <div class="container">
<div class="session d-flex justify-content-center">
    <form action="" class="log-in" autocomplete="off"> 
        <a href="" class="d-flex align-items-center justify-content-center mb-3"><img src="assets/images/R1-logo.svg" alt="RPOST" style="height: 75px;"></a>
        <h3 class="custom-Multi-Factor text-center mb-3" style="font-size: 20px;">Reset your password</h3>
        <p class="mb-0 text-center mb-3" style="opacity: .8;">Your password is set to expire in {{numDaysToExpire}} days. We request you to change the password.</p>
        <div class="d-flex justify-content-center align-items-center">
        <a [routerLink]="['/reset-password']" class="rpost_blog_btn">Change My Password</a> 
    </div>    
    <div class="d-flex justify-content-end mb-0 custom-skip-btn">
        <a   (click)="continueClickEvent()" class="verify-btn" style="cursor: pointer;">Continue<i class="fa fa-arrow-right ml-1" aria-hidden="true"></i>
        </a>
    </div>  
      </form>
</div>
    </div>
   
</main>