import { Component } from '@angular/core';
import { CommonUtilService } from '../service/common-util.service';

@Component({
  selector: 'app-password-expired',
  templateUrl: './password-expired.component.html',
  styleUrls: ['./password-expired.component.css']
})
export class PasswordExpiredComponent {

  constructor(private commonUtilSvc:CommonUtilService
    ){
   
      
  }

async ngOnInit(): Promise<void> {
    this.commonUtilSvc.hideCommonProgressBar();
    
  }
}
