
export class VerifyOTPRequestModel
{
    MFA_Token!: string;
    AccessCode!: string;
    SourceClient_Id!:string;
    RedirectURI!:string;
    Client_Id!:string;
    TrustDevice!: boolean;
    Browser_Device!:string;
}