import { Injectable } from '@angular/core';
import { SocialAuthService, SocialUser } from "@abacritt/angularx-social-login";
import { GoogleLoginProvider } from "@abacritt/angularx-social-login";
import { Observable } from 'rxjs';

declare const gapi: any;//this line should be at the very top of your TS file

@Injectable({
  providedIn: 'root'
})
export class GoogleAuthenticationService {
  user: SocialUser | undefined;
  loggedIn: boolean | undefined;
  accessToken: any='';
  public auth2: any;
  constructor(private authService: SocialAuthService) { }

  googleSignIn()
  {
    gapi.auth2.getAuthInstance().signIn().then(function(response: any){
      //If Google OAuth 2 works fine
      console.log(response);
  }, function(error: { error: string; }){
      //If Google OAuth 2 occured error
      console.log(error);
      if(error.error === 'popup_closed_by_user'){
          alert('Oh Dude, Why you close authentication user window...!');
      }
  });
  }
  refreshToken() {
    return this.authService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
  }

  getAccessToken(): void {
    this.authService.getAccessToken(GoogleLoginProvider.PROVIDER_ID)
    .then(accessToken => this.accessToken = accessToken);
   // alert(this.accessToken);
  }

  signOut(callbackUrl:string): void {
    this.authService.signOut(true);
    //this.googleLogout(callbackUrl);
    window.location.href=callbackUrl;
    
  }

  public googleLogout(callbackUrl:string) {
    /* gapi.load('auth2', () => {
      this.auth2 = gapi.auth2.getAuthInstance();
      this.auth2.signOut().then(function() {
        alert('signedout');
        console.log("User signed out");
        window.location.href=callbackUrl;
      });
      this.auth2.disconnect();
      //this.attachSignout(document.getElementById('googleBtn2'));
    }); */
          var auth2 = gapi.auth2.getAuthInstance();
      auth2.signOut().then(function () {
      });
      auth2.disconnect();
  }

  
}
