import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AzureTokenResponseService {

  constructor() { }

  getRefreshTokenJsonFromLocalStorage() {

    var values = [],
        keys = Object.keys(localStorage),
        i = keys.length;
    var localStorageItem=''
    while ( i-- ) {
       if(keys[i].indexOf('login.windows.net-refreshtoken')!=-1)
       {
          localStorageItem=localStorage.getItem(keys[i])!;
          break;
       }
       
    }
    let jsonObj: any = JSON.parse(localStorageItem); 
    return jsonObj;
  }

  getIdTokenJsonFromLocalStorage() {

    var values = [],
        keys = Object.keys(localStorage),
        i = keys.length;
    var localStorageItem=''
    while ( i-- ) {
       if(keys[i].indexOf('login.windows.net-idtoken')!=-1)
       {
          localStorageItem=localStorage.getItem(keys[i])!;
          break;
       }
       
    }
    let jsonObj: any = JSON.parse(localStorageItem); 
    return jsonObj;
  }

  getAccessTokenJsonFromLocalStorage() {

    var values = [],
        keys = Object.keys(localStorage),
        i = keys.length;
    var localStorageItem=''
    while ( i-- ) {
       if(keys[i].indexOf('login.windows.net-accesstoken')!=-1)
       {
          localStorageItem=localStorage.getItem(keys[i])!;
          break;
       }
       
    }
    let jsonObj: any = JSON.parse(localStorageItem); 
    return jsonObj;
  }

  getIdTokenCliamsJsonFromLocalStorage() {

    var values = [],
        keys = Object.keys(localStorage),
        i = keys.length;
    var localStorageItem=''
    while ( i-- ) {
       if(keys[i].indexOf('login.windows.net')!=-1
        && keys[i].indexOf('login.windows.net-refreshtoken')==-1
        && keys[i].indexOf('login.windows.net-idtoken')==-1 
        && keys[i].indexOf('login.windows.net-accesstoken')==-1)
       {
          localStorageItem=localStorage.getItem(keys[i])!;
          break;
       }
       
    }
    let jsonObj: any = JSON.parse(localStorageItem); 
    return jsonObj;
  }
  
}
