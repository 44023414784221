import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, catchError, map, of, takeWhile, tap, timer } from 'rxjs';
import { CommonService } from '../service/common/common.service';
import { MFAAuthenticationService} from '../service/mfaauthentication.service'
import { MFATokenRequestModel } from '../Model/MFA/MFATokenRequestModel';
import { IMFATokenResponseDetails } from '../Model/MFA/MFATokenResponse';
import { TranslateModule, TranslateLoader, TranslateService} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { TranslationLoaderService } from '../common/translation-loader.service';
import { UserSessionService } from '../common/user-session.service';
import { CommonUtilService } from '../service/common-util.service';
import { MFATrustDeviceModel } from '../Model/MFA/MFATrustDeviceModel';
import { DeviceDetectorService } from 'ngx-device-detector';
import { VerifyOTPRequestModel } from '../Model/MFA/VerifyOTPRequestModel';
import { IVerifyOTPResponseDetails, JwtToken } from '../Model/MFA/VerifyOTPResponseModel';
import { QueryStringValuesModel } from '../Model/MFA/QueryStringValuesModel';
import { callbackify } from 'util';
import { ResponseMsgToClient } from '../Model/MFA/ResponseMsgToClient';
import { AppError } from '../common/class/app-error';
import { IValidateMFATokenResponseDetails } from '../Model/MFA/ValidateMFATokenResponse';
import { IResendOTPResponseDetails } from '../Model/MFA/ResendOTPResponseModel';
import { error } from 'console';
import { ErrorReason } from '../Model/ErrorReasonModel';
import { FormControl, FormGroup } from '@angular/forms';
import { AppConfigService } from '../app-config.service';
import { LocationStrategy } from '@angular/common';
//import * as $ from 'jquery';
@Component({
  selector: 'app-email-autentication',
  templateUrl: './email-autentication.component.html',
  styleUrls: ['./email-autentication.component.css']
})
export class EmailAutenticationComponent {
  qs='';
  clientIdQs='';
  loginUrlQs='';
  counter = 59;
  counterToDisplay='';
  maskedEmailAddress='';
  emailAddress='';
  sourceClientId='';
  callBackUrl='';
  public mfaTokenRequestModelObj=new MFATokenRequestModel();
  message: any;
  validateTokenSnapShot:IValidateMFATokenResponseDetails | undefined;
  errorMessage: any;
  appLang: any;
  fieldTextType: boolean | undefined;
  mfaForm: any;
  resendOTPAttemptCounter:number =0;
  rcapClientId:string | undefined;
  emailAddressFromResp:string | undefined;
  stepper: any;
constructor(private route: ActivatedRoute, private commonUtilSvc:CommonUtilService
  , private mfaSvc:MFAAuthenticationService
  ,private translate: TranslateService
  ,private deviceService: DeviceDetectorService
  ,private langSvc: TranslateService
    ,private langLoaderSvc: TranslationLoaderService
    ,private user: UserSessionService
    ,private router:Router
    ,private config: AppConfigService
    ,private location: LocationStrategy
    ){
  this.route.queryParams.subscribe(params => {
    this.qs = params['qs'];
    
    
    
   });


   this.rcapClientId=this.config.rcapClientId;
   this.disableBrowserBackButtonAngular();
    
}
disableBrowserBackButtonAngular()
{
  history.pushState(null, null!, window.location.href);
    // check if back or forward button is pressed.
    this.location.onPopState(() => {
        history.pushState(null, null!, window.location.href);
        this.stepper.previous();
    });
}
showProgressBar()
{
  $("#divProcessing").show();
}

hideProgressBar()
{
  $("#divProcessing").hide();
}
toggleFieldTextType() {
  this.fieldTextType = !this.fieldTextType;
}
  tabOut(event:any)
  {
    
    if(event.target.value.length==6)
            {
              this.enableButton('ancVerifyBtn');
            }
            else{
              this.disableButton('ancVerifyBtn');
            }
    
  }
  displayValidationErroMsg(errorMsgToDisplay:string)
  {
    
    //$('#divErrorMsg').show();
    $('#divErrorMsg').css("display","flex");
    $('#divErrorMsg').css("justify-content","center");
    $('#parErrorMsg').text(errorMsgToDisplay);
  }
  hideValidationErroMsg()
  {
    //$('#divErrorMsg').hide();
    $('#divErrorMsg').css("display","none");
    $('#parErrorMsg').text('');
  }
  displaySuccessMsg(msg:string)
  {
    //$('#divSuccessMsg').show();
    //$('#divSuccessMsg').css("display","block");
    $('#divSuccessMsg').css("display","flex");
    $('#divSuccessMsg').css("justify-content","center");
    $('#parSuccessMsg').text(msg);
  }
  hideSuccessMsg()
  {
    //$('#divSuccessMsg').hide();
    $('#divSuccessMsg').css("display","none");
    $('#parSuccessMsg').text('');
  }
  InitLang() {
    const lang =  this.user.getAppLanguage();
    const browserLang = navigator.language.toLowerCase();
    if (lang === browserLang) {
      this.langLoaderSvc.use(lang);
      this.appLang = lang;
    } else {
      this.langLoaderSvc.use(lang);
      this.appLang = browserLang;
    }
  }
  
  onKeyDownEvent(event: any)
  {
    var keyCode = event.keyCode || event.which; 
			  if (keyCode == 9) {  //If it's the tab key
            let val : any =$('#txtOTP').val()!;
            if(val.length==6)
            {
              this.enableButton('ancVerifyBtn');
            }
        }
  }
  buildForm()
  {
    this.mfaForm = new FormGroup({
      txtOTP: new FormControl()
  });
  }
  checkIsPageRefreshed()
  {
    window.addEventListener("beforeunload", function (e) {
      var confirmationMessage = "\o/";
      console.log("cond");
      e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
      return confirmationMessage;              // Gecko, WebKit, Chrome <34
  });
  }
  disableBack() { window.history.forward(); }
  async ngOnInit(): Promise<void> {
    //setTimeout( () => {
      this.disableBack();
    //}, 1000);
    
    this.commonUtilSvc.hideCommonProgressBar();
    this.buildForm();
    //$('#ancResend').attr("disabled","disabled");
    //this.commonUtilSvc.displaySpinner();
    //$("#divProcessing").hide();
    this.hideProgressBar();
  this.getTimer();
  this.disableButton('ancResend');
  //this.ValidateMFAToken();
  let qsResults= this.GetQueryStringValues().then( qsValues => { 
    
    if(localStorage.getItem(qsValues.MFAToken)!=null && localStorage.getItem(qsValues.MFAToken)!=undefined)
    {
      let errorMsg=  ErrorReason.PAGE_REFRESH_IS_NOT_ALLOWED;//'Redirected due to page refresh';
      this.redirectToLoginWithErrorReason(errorMsg,qsValues.LoginUrl);
    }
    else
    {
      localStorage.setItem(qsValues.MFAToken,"1");
    }
    if(this.route.snapshot.data['validateTokenDetails']!=undefined)
    {
      this.validateTokenSnapShot=this.route.snapshot.data['validateTokenDetails'];
      if(this.validateTokenSnapShot!=null && this.validateTokenSnapShot?.resultContent!=null 
        && this.validateTokenSnapShot?.resultContent!=undefined)
        {
          this.emailAddressFromResp=this.validateTokenSnapShot?.resultContent.emailAddress;
        }
      
      this.appLang=qsValues.Language;
      if(this.appLang ==null)
      {
        this.appLang='en-us';
      }
      
      if(this.validateTokenSnapShot!=null && this.validateTokenSnapShot.Message!=null 
        && this.validateTokenSnapShot.Message!=undefined )
      {
        let errorMsgToDisplay=this.validateTokenSnapShot.Message;
        //this.displayValidationErroMsg(errorMsgToDisplay);
        this.displayErrorMsgAndRedirectToLoginWhenMFATokenInvalid(errorMsgToDisplay,qsValues.LoginUrl);
        // setTimeout(function () {
        //   window.location.href=qsValues.LoginUrl+'?errorReason='+errorMsgToDisplay;
        // }, 3000);
        
      }
    }
    else
    {
      this.appLang='en-us';
    }
    this.langSvc.setDefaultLang(this.appLang);
    localStorage.setItem('appLang',this.appLang);
    this.InitLang();
    
    this.maskedEmailAddress= this.getMaskedEmailAddress_V1(this.emailAddressFromResp?.toLowerCase()!);//this.getMaskedEmailAddress(qsValues.EmailAddress);
    var mfaToken=qsValues.MFAToken;
    if(localStorage.getItem("Error_ValidateToken_"+mfaToken)!=null && localStorage.getItem("Error_ValidateToken_"+mfaToken)!=undefined)
    {
          
          var errorMsgToDisplay=localStorage.getItem("Error_ValidateToken_"+mfaToken)?.toString()!;
          this.displayValidationErroMsg(errorMsgToDisplay);
          localStorage.removeItem("Error_ValidateToken_"+mfaToken);
    }
    
  });
  
  
}
resendOTP(event: any)
{
  $('#txtOTP').val('');
  this.disableButton('ancResend');
  this.disableButton('ancVerifyBtn');
  this.hideSuccessMsg();
  this.hideValidationErroMsg();
  let mfaTokenRequestObj= this.GetQueryStringValues().then( result => { 
  let resendOTPCounter=0;
    this.resendOTPAttemptCounter= this.resendOTPAttemptCounter+1;
    
      this.showProgressBar();
      
        if(this.resendOTPAttemptCounter>2)
        {
            this.disableButton('ancResend');
            this.hideProgressBar();
            
        }
        else if(this.resendOTPAttemptCounter<=2)
        {
          resendOTPCounter=resendOTPCounter+1;
          localStorage.setItem("ResendOTPCounter_"+result.MFAToken,resendOTPCounter.toString());
                this.mfaSvc.ReSendOTP(result.MFAToken,result.SourceClientId, result.ClientId,result.CallBackUrl)
          .subscribe({
            next: (resp) => {
              console.log(resp);
              //$("#divProcessing").hide();
              this.hideProgressBar();
              //this.enableButton('ancResend');
                  if (!(resp instanceof AppError)) {      
                    //this.getTimer();
              const response = resp as unknown as IResendOTPResponseDetails;
              const result =  response.resultContent;
              if(result==null)
              {
                  this.displayValidationErroMsg("Error Occured");
              }
              else
              {
                if(result.accessCodeEmailed)
                {
                  if(this.resendOTPAttemptCounter==1)
                  {
                    var succMsg=this.translate.instant('EmailAuthentication.ResendOTPSentSuccessMsg');
                    this.displaySuccessMsg(succMsg);
                    this.getTimer();
                  }
                  else if(this.resendOTPAttemptCounter==2){
                    
                    var OTPRequestExceededErrorMsg1=this.translate.instant('EmailAuthentication.OTPRequestExceededErrorMsg1');
                    var OTPRequestExceededErrorMsg2=this.translate.instant('EmailAuthentication.OTPRequestExceededErrorMsg2');
                    
                    let successMsg= OTPRequestExceededErrorMsg1 + " " + OTPRequestExceededErrorMsg2;
                    //this.displayValidationErroMsg(errorMsg);
                    this.displaySuccessMsg(successMsg);
                    
                    this.hideProgressBar();                    

                  }
                  
                  //this.enableButton('ancResend');
                }
                  
              }
              
            }
            else
            {
              this.hideProgressBar();
              const response = resp as AppError;
              let errorMsg=response.Message!;
              //this.displayValidationErroMsg(errorMsg);
              this.displayErrorMsgAndRedirectToLoginWhenMFATokenInvalid(errorMsg,result.LoginUrl);
              
              //return true;
              //let errorMessage=this.translate.instant('ReceiptAuthentication.GenericErrorMsg');
              //this.DisplayErrorMessage(errorMessage);
              // this.DisplayErrorMessage(response.Message);

            }
            }
            ,
            error: (e) => console.error(e)
          });
          }
          else{   
            var OTPRequestExceededErrorMsg1=this.translate.instant('EmailAuthentication.OTPRequestExceededErrorMsg1');
            var OTPRequestExceededErrorMsg2=this.translate.instant('EmailAuthentication.OTPRequestExceededErrorMsg2');
            
            let successMsg= OTPRequestExceededErrorMsg1 + " " + OTPRequestExceededErrorMsg2;
            //this.displayValidationErroMsg(errorMsg);
            this.displaySuccessMsg(successMsg);
            //event.stopPropagation();
            this.hideProgressBar();
          }
         // }
          
          
        });
 
  

}


async GetQueryStringValues():Promise<QueryStringValuesModel>
{
  let queryStringValuesObj= new QueryStringValuesModel();
  let qs=this.commonUtilSvc.ConvertBase64ToString(this.qs);
  console.log(qs);
  let emailAddress='';//this.commonUtilSvc.GetParameterByName("?EmailAddress",qs)!;
  let password=''; //this.commonUtilSvc.GetParameterByName("Pwd",qs)!;
  let clientId= this.rcapClientId!; //this.commonUtilSvc.GetParameterByName("ClientId",qs)!;
  let sourceClientId= this.commonUtilSvc.GetParameterByName("?SourceClientId",qs)!;
  let mfaToken=this.commonUtilSvc.GetParameterByName("MFAToken",qs)!;
  let callBackUrl=this.commonUtilSvc.GetParameterByName("CallBackUrl",qs)!;
  let mfaEnabled= this.commonUtilSvc.GetParameterByName("MFAEEnabled",qs)!;
  let mfaEnabledBoolValue=(mfaEnabled=="true");
  let passwordChangeType=parseInt(this.commonUtilSvc.GetParameterByName("MFAEEnabled",qs)!);
  let loginUrl=this.commonUtilSvc.GetParameterByName("LoginUrl",qs)!;
  let lang=this.commonUtilSvc.GetParameterByName("Lang",qs)!;
  
  queryStringValuesObj.EmailAddress=emailAddress;
  queryStringValuesObj.Password=password;
  queryStringValuesObj.ClientId=clientId;
  queryStringValuesObj.SourceClientId=sourceClientId;
  queryStringValuesObj.MFAToken=mfaToken;
  queryStringValuesObj.CallBackUrl=callBackUrl;
  queryStringValuesObj.MFAEEnabled=mfaEnabledBoolValue;
  queryStringValuesObj.PasswordChangeType=passwordChangeType;
  queryStringValuesObj.LoginUrl=loginUrl;
  queryStringValuesObj.Language=lang;
  return queryStringValuesObj;
  
  
}



getTimer()
{
  this.counter=59;
  timer(1000, 1000) //Initial delay 1 seconds and interval countdown also 1 second
      .pipe(
        takeWhile( () => this.counter > 0 ),
        tap(() => this.counter-- )
      )
      .subscribe( () => {
        //add you more code
        //alert(this.counter);
        if(this.counter==0)
          {
            this.counter=0;
            //this.counterToDisplay=
            // $('#ancResend').removeClass('btn-Disabled');
            // $('#ancResend').addClass('btn-Enabled');
            this.enableButton('ancResend');
          }

      } );
      
}

disableButton(btnId:string)
{
  if(btnId=='ancVerifyBtn')
  {
    
    $('#'+btnId+'').removeClass('verify-btn');
    $('#'+btnId+'').addClass('verify-btn-disabled');
    
  }
  else
  {
    $('#'+btnId+'').removeClass('btn-Enabled');
    $('#'+btnId+'').addClass('btn-Disabled');
  }
  
  $('#'+btnId+'').prop( "disabled", true);

  // $('#'+btnId+'').removeClass('btn-Enabled');
  // $('#'+btnId+'').addClass('btn-Disabled');
  // $('#'+btnId+'').click(function() { return false; }); 
  // $('#ancResend').removeClass('btn-Enabled');
  // $('#ancResend').addClass('btn-Disabled');
}

enableButton(btnId:string)
{
  if(btnId=='ancVerifyBtn')
  {
    $('#'+btnId+'').removeClass('verify-btn-disabled');
    $('#'+btnId+'').addClass('verify-btn');
    
  }
  else
  {
    $('#'+btnId+'').removeClass('btn-Disabled');
    $('#'+btnId+'').addClass('btn-Enabled');
  }
  $('#'+btnId+'').prop( "disabled", false);

}

getMaskedEmailAddress(emailAddress:string):string
{
  var maskedEmailAddress=emailAddress;
  var loopCounter=0;
  for(var i=0; i<emailAddress.length; i++)
  {
    loopCounter=loopCounter+1;
    let lastPosition=emailAddress.indexOf("@");
    let posValueAfterAtTheRateSymbol=lastPosition+1;
    if(loopCounter>1 && lastPosition!=loopCounter && posValueAfterAtTheRateSymbol!=loopCounter 
      && posValueAfterAtTheRateSymbol+1 !=loopCounter)
    {
        maskedEmailAddress=replaceAtPosition(maskedEmailAddress,loopCounter);
    }
     
  }
  return maskedEmailAddress;
}

getMaskedEmailAddress_V1(emailAddress:string):string
{
  var maskedEmailAddress=emailAddress;
  var loopCounter=0;
  let lastPosition=emailAddress.indexOf("@");
  let posValueAfterAtTheRateSymbol=lastPosition+1;
  for(var i=0; i<emailAddress.length; i++)
  {
    loopCounter=loopCounter+1;

    if(loopCounter<lastPosition)//before @ symbol
    {
      let difference=Math.abs(lastPosition-loopCounter);
      if(difference<=3)
      {
        maskedEmailAddress=replaceAtPosition(maskedEmailAddress,loopCounter);
        
      }
    }
    if(loopCounter>lastPosition)// after @ symbol
    {
      let difference=Math.abs(loopCounter-lastPosition);
      if(difference<=3)
      {
        maskedEmailAddress=replaceAtPosition(maskedEmailAddress,loopCounter);
        
      }
    }
     
  }
  return maskedEmailAddress;
}
saveTrustDeviceInformation(values:any):void {
  console.log(values.currentTarget.checked);
  //store in json format
  let browserDeviceInfo= this.deviceService.browser +"/"+ this.deviceService.device;
  let trustDeviceInfoJson=this.getTrustDeviceDetails_Json(values.currentTarget.checked,browserDeviceInfo)
  localStorage.setItem("trustDeviceInfo_"+this.emailAddress,trustDeviceInfoJson);
}


getTrustDeviceDetails_Json(isTrustDeviceCheckboxChecked:boolean,deviceInfo:string)
{
  var trustDeviceObj=new MFATrustDeviceModel();
  trustDeviceObj.TrustDevice=isTrustDeviceCheckboxChecked;
  trustDeviceObj.Device=deviceInfo;
  return this.commonUtilSvc.ConvertObjToJsonString(trustDeviceObj);
}


verifyOTP()
{
  this.hideSuccessMsg();
  this.hideValidationErroMsg();
  let otpValue=$('#txtOTP').val()?.toString()!;
  
  if(otpValue=='')
  {
    
    var OTPRequiredErrorMsg=this.translate.instant('EmailAuthentication.OTPRequiredErrorMsg');
    this.displayValidationErroMsg(OTPRequiredErrorMsg);
    
  }
  else{
      this.disableButton('ancVerifyBtn');
      
      this.showProgressBar();
      let queryStringValuesObj= new QueryStringValuesModel();
      let qs=this.commonUtilSvc.ConvertBase64ToString(this.qs);
      console.log(qs);
      
      let clientId= this.rcapClientId!;
      let sourceClientId= this.commonUtilSvc.GetParameterByName("?SourceClientId",qs)!;
      let mfaToken=this.commonUtilSvc.GetParameterByName("MFAToken",qs)!;
      let callBackUrl=this.commonUtilSvc.GetParameterByName("CallBackUrl",qs)!;
      let mfaEnabled= this.commonUtilSvc.GetParameterByName("MFAEEnabled",qs)!;
      let mfaEnabledBoolValue=(mfaEnabled=="true");
      let passwordChangeType=parseInt(this.commonUtilSvc.GetParameterByName("MFAEEnabled",qs)!);
      let loginUrl=this.commonUtilSvc.GetParameterByName("LoginUrl",qs)!;
      let randomNumber= this.commonUtilSvc.GetRandomNumber()+'_'+mfaToken;

      let browserDeviceInfo= this.commonUtilSvc.getBrowserUserAgent();//this.deviceService.browser +"-"+ this.deviceService.device;
      
      let clientAppLoginUrl=queryStringValuesObj.LoginUrl;
      
      if(localStorage.getItem("mfaToken")!=null && localStorage.getItem("mfaToken")!=undefined)
      {
          mfaToken=localStorage.getItem("mfaToken")!;
      }
      let trustDevice=$("#chkTrustDeviceCheckBox").prop("checked");
      localStorage.setItem("trustDeviceChecked_"+mfaToken,trustDevice);
      let trustDeviceExpirationDate='';
      var verifyOTPRequestModelObj=new VerifyOTPRequestModel();
      verifyOTPRequestModelObj.AccessCode=otpValue;
      verifyOTPRequestModelObj.Browser_Device=browserDeviceInfo;
      verifyOTPRequestModelObj.MFA_Token=mfaToken;
      verifyOTPRequestModelObj.SourceClient_Id=sourceClientId;
      verifyOTPRequestModelObj.TrustDevice=trustDevice;
      verifyOTPRequestModelObj.Client_Id=clientId;
      verifyOTPRequestModelObj.RedirectURI=callBackUrl;
      this.mfaSvc.VerifyOTP(verifyOTPRequestModelObj)
          .subscribe({
            next: (resp) => {
              console.log(resp);
              
              this.hideProgressBar();
              this.enableButton('ancVerifyBtn');
                  if (!(resp instanceof AppError)) {      
          
              const response = resp as unknown as IVerifyOTPResponseDetails;
              const result =  response.resultContent;
              
              if(result==null)
              {
                  this.displayValidationErroMsg("Error Occured");
              }
              //if OTP is valid, no pwd change
              else if(result.accessCodeValid)
              {
                localStorage.setItem("TrustDeviceExpirationDate_"+mfaToken,result.trustDeviceExpirationDate);
                trustDeviceExpirationDate=result.trustDeviceExpirationDate;
                if(result.passwordChange==0)
                {
                  //Redirect to Client Application and post message
                  console.log("Before iframe creation");
                  this.commonUtilSvc.generateDataShareIframeHtmlElement(callBackUrl,clientId);
                  console.log("After iframe creation");
                  let mfaToken_After_Verify_OTP=mfaToken;
                  localStorage.setItem("MFAToken",mfaToken);
                  localStorage.setItem("LoginUrl_"+mfaToken,loginUrl);
                  localStorage.setItem("RedirectURI_"+mfaToken,callBackUrl);
                  localStorage.setItem("SourceClientId_"+ mfaToken,sourceClientId);
                  localStorage.setItem("ClientId_"+ mfaToken,clientId);
                  
                  //let accessToken=(result.jwtToken!=null)?result.jwtToken.access_token:"";
                  let msgToPost_Json='';
                  if(result.access_Token!=null &&  result.access_Token!=undefined)
                    {
                      msgToPost_Json=this.commonUtilSvc.prepareMsgToPost_Json(mfaToken_After_Verify_OTP,trustDevice
                              ,result.access_Token,trustDeviceExpirationDate);
                      if(msgToPost_Json!=null && msgToPost_Json!=undefined && msgToPost_Json!='')
                      {
                        localStorage.setItem("MsgToPost_"+mfaToken,msgToPost_Json);
                        
                      }
                      else
                      {
                          let errorMsg=ErrorReason.MESSAGE_TO_POST_IS_EMPTY
                        this.displayValidationErroMsg(errorMsg); 
                        setTimeout(function () {
                          window.location.href=loginUrl+'?errorReason='+errorMsg;
                        }, 3000);

                      }
                      
                    }
                    else
                    {
                      let errorMsg=ErrorReason.ACCESS_TOKEN_IS_EMPTY
                      this.displayValidationErroMsg(errorMsg); 
                      setTimeout(function () {
                        window.location.href=loginUrl+'?errorReason='+errorMsg;
                      }, 3000);
                    }
                  
                    this.commonUtilSvc.postMessageToClient(msgToPost_Json,"iframe_"+randomNumber,callBackUrl,clientId,clientAppLoginUrl);
                }
                else if(result.passwordChange==-1 || result.passwordChange==1)
                {
                  var numOfDaysToExpire=result.passwordExpiresInDays; 
                  
                  //Redirect to Password expired screen
                  
                  //store message in local storage to post to client
                  
                  if(result.passwordChange == -1 || (result.passwordChange == 1 && numOfDaysToExpire==0))
                  {
                    this.router.navigate(['/password-expired']);
                  }
                  else if(result.passwordChange == 1 && numOfDaysToExpire>0)
                  {
                    
                    localStorage.setItem("OTPVerified_"+mfaToken,'true');
                    this.router.navigate(['/reset-password-reminder'],{queryParams: {qs: numOfDaysToExpire}});
                  }
                  
                }
                
              }
              
          
      }
      else
      {
        
        
        const response = resp as AppError;
        let errorMsg=response.Message!;
        let errorCode='';
        if(response.originalError!=null && response.originalError!=undefined 
            && response.originalError.error!=null && response.originalError.error!=undefined
            && response.originalError.error.resultContent!=undefined
            && response.originalError.error.resultContent.errors!=null 
            && response.originalError.error.resultContent.errors!=undefined)
            {
               errorCode=response.originalError.error.resultContent.errors[0].code;
               
            }
            else if(response.originalError!=null && response.originalError!=undefined 
              && response.originalError.error!=null && response.originalError.error!=undefined
              && response.originalError.error.message !=null && response.originalError.error.message!=undefined
              )
            {
               errorCode=response.originalError.error.message.messageCode;
            }

            if(errorCode!=null && errorCode!=undefined)
            {
              if(errorCode=='RCAP-1040')
               {
                  errorMsg=this.translate.instant('EmailAuthentication.IncorrectOrExpiredAccessCode');
               }
               else if(errorCode=='RCAP-1051')
               {
                  let errorMsgPart1= this.translate.instant('EmailAuthentication.OTPMultipleInvalidAttemptsErroMsg1');
                  let errorMsgPart2= this.translate.instant('EmailAuthentication.OTPMultipleInvalidAttemptsErroMsg2');
                  errorMsg=errorMsgPart1 + ' ' + errorMsgPart2;
                  //Redirect after few seconds
                  this.redirectToLoginWithErrorReason(errorCode,loginUrl);

               }
               else if(errorCode == 'RCAP-1025') //MFA token is invalid
               {
                  errorMsg=this.translate.instant('EmailAuthentication.MFATokenInValidMsg');
                  //Redirect after few seconds
                  this.redirectToLoginWithErrorReason(errorMsg,loginUrl);
               }
            }
            
        //this.displayValidationErroMsg(errorMsg);
        this.displayErrorMsgAndRedirectToLoginWhenMFATokenInvalid(errorMsg,loginUrl);
        
        

      }
            }
            ,
            error: (e) => console.error(e)
          });
    }
}

redirectToLoginWithErrorReason(errorMsg:string,loginUrl:string)
{
  //Redirect after few seconds
  setTimeout(function () {
    window.location.href=loginUrl+'?errorReason='+errorMsg;
      }, 3000);
}

displayErrorMsgAndRedirectToLoginWhenMFATokenInvalid(errorMsg:string,loginUrl:string)
{
  let invalidMfaTokenErrorKey=ErrorReason.INVALID_MFA_TOKEN_ERROR_MSG_KEY;
  let invalidMfaTokenErrorMsg= ErrorReason.INVALID_MFA_TOKEN_ERROR_MSG;
  let sessionExpiryMsg=ErrorReason.INVALID_MFA_TOKEN_ERROR_MESG1;
  //this.displayValidationErroMsg(sessionExpiryMsg);
  if(errorMsg.indexOf(invalidMfaTokenErrorMsg.toLowerCase())!=-1 || errorMsg=='MFA token is invalid')
        {
          this.displayValidationErroMsg(sessionExpiryMsg);
          setTimeout(function () {
            window.location.href=loginUrl+'?errorReason='+sessionExpiryMsg;
          }, 3000);
        }
        else{
            this.displayValidationErroMsg(errorMsg);
        }
}


}

function replaceAtPosition(originalString: string,index: any) {
  
  return  setCharAt(originalString,index,'x');
  
}

function setCharAt(str: string,index: number,chr: string) {
  if(index > str.length-1) return str;
  return str.substring(0,index) + chr + str.substring(index+1);
}

        
