<img class="wave" src="../assets/images/wave_yellow.svg">
<main class="login_wrapper my3">
   <div class="container">
      <div class="row login-box-12 container-box">
         <div class="col-lg-12 col-sm-12" style=" background: #f7fbff80;">
            <div class="login-inner-form">
               <div class="details">
                  <div class="d-flex align-items-center mt-2 mb-4">
                     <button (click)="CancelCurrentRequest()" class="fw-bold btn btn-outline-danger btn hw34 shadowbtn"
                        href="/#">
                     <span class="material-icons " style="font-size: 18px;font-weight: 600;">west</span> </button>
                     <!--<h3 class="font-26 mb-0 d-flex justify-content-center w-100 pb-0"
                        style="margin-right: 36px !important; color:#33373e; ">Login with... </h3>-->
                    <h3 class="font-26 mb-0 d-flex justify-content-center w-100 pb-0"
                   style="margin-right: 36px !important; color:#33373e; ">{{"SSOAuthentication.LoginWithText" | translate}}</h3>
                        
                  </div>
                  <!-- <h5 style="font-size: 1rem; color: #000000;" class="mb-3">Use your account username when you use one of the
                     single sign-on platforms to log in. 
                  </h5> -->
                  <h5 style="font-size: 1rem; color: #000000;" class="mb-3">{{"SSOAuthentication.AccountText" | translate}}
                  </h5>
                  <div class="d-flex flex-column mb-3">
                     <!-- <p style="font-size: 1rem;color: #000000;">You will be redirected to the application once you sign in.</p> -->
                     <p style="font-size: 1rem;color: #000000;">{{"SSOAuthentication.RedirectText" | translate}}</p>
                  </div>
                  <div id="pnlSSOButtons" style="display:none !important; margin: 0 auto;" class="col-md-5 pb-0 mt-3">
                     <div class="d-flex justify-content-left align-items-left mb-2 justify-content-left"
                        title="Continue with Google" id="pnlGoogleBtn">
                        <div class="d-flex justify-content-left align-items-left">
                           <asl-google-signin-button type='standard' id="" class="btn-secondary" width="230"
                              theme='outline' size='medium' shape="square" data-auto_prompt="false" data-text="continue_with"
                              text="continue_with" data-logo_alignment="left" style="background-color: #f7fbff80 !important;
                              color: #e8eaed !important; padding: 0 !important; border: none;" #googleBtnRef></asl-google-signin-button>
                    
                        </div>
                     </div>
                     <div class="d-flex justify-content-left align-items-left justify-content-left">
                        <button (click)="AzureSignIn()" data-toggle="tooltip" title="Sign in with Microsoft"
                           class="btn btn-secondary hw-40 btn-lg btn-block btn-rounded custom-border-icons d-flex align-items-left"
                           type="submit" id="btnAzureLogin">
                        <img
                           src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Microsoft_logo.svg/768px-Microsoft_logo.svg.png?20210729021049"
                           class="img-hw">
                        <span class="custom-microsoft-contents">Continue with Microsoft</span></button>
                     </div>
                  
                  </div>
                  <div id="pnlSpinner" style="margin-bottom:-85px;display:none !important;"
                     class="form-group mt-10 d-flex flex-column justify-content-center">
                     <img src="../assets/images/orange-loader.gif" alt="" class="img1">
                     <!-- <label class="green">Please wait . . .</label> -->
                  </div>
                  <div id="pnlComingSoon" style="display:none;">
                     <p class="lbl-coming-soon">Coming soon..</p>
                  </div>
                  <div id="pnlPopupblockerMsg" style="display: none;">
                     <h5 class="mt-3 font-style"> <label class="mr-1" style="color: #000000">Note : </label> <label
                        id="lblPopupBlockerMsg"></label> Go to settings and disable the popup block. </h5>
                  </div>

               </div>
            </div>
         </div>
      </div>
   </div>
   <div>
   </div>
</main>
<style>
   .nsm7Bb-HzV7m-LgbsSe {
   background-color: #fff !important;
   background-image: none;
   /* border: 1px solid #fbfdff !important; */
   font-size: 25px;
   }
</style>