
 
 <img class="wave" src="../assets/images/wave_yellow.svg">
 <main class="login_wrapper my3">
	 
	 <div class="container">
	 
			 <div class="row login-box-12 container-box">
			 
				 <!-- <button  (click)="AzureSignIn()" type="button" id="btnAzureAdSignIn" class="btn btn-success">Sign in using Azure AD </button> -->
			 
				 <div class="col-lg-12 col-sm-12" style=" background: #f7fbff80;">
					 <div class="login-inner-form">
						 <div class="details">
							<div class="d-flex align-items-center">
								
									
							</div>
							 <h3 class="font-26 mt-3 mb-3" style="color:#33373e;  margin-top: -10px; ">See you soon! </h3>
							 <h5 class="mb-3">Signing off.. Please wait.. </h5>
			   <div class="d-flex flex-column mb-3">
								 
				 <p style="font-size: 1rem;color: #000000;">You will be redirected to the login page once you sign out!</p>
				 </div>
				 
							 
			<div id="pnlSpinner" style="margin-bottom:-85px;display:none !important;" class="form-group mt-10 d-flex flex-column justify-content-center">
			<img src="../assets/images/orange-loader.gif" alt="" class="img1" > 
			<!-- <label class="green">Please wait . . .</label> -->
			</div>
		  <div class="d-flex align-items-center justify-content-center mb-4">
        <button  (click)="SignOutButtonClick()" class="fw-bold btn btn-outline-danger btn px-3 logoffbtn d-flex align-items-center" href="/#">
           Sign out</button>
      </div>
		   
		   <!-- <div>
			<button id="btnCancel" text="Cancel" (click)="CancelCurrentRequest()">
			<i ></i>Cancel
                                    
			</button>
			
			
		   </div> -->
		   
						 </div>
					 </div>
				 </div>
			 
			 </div>
	 
	 </div>
	 
	 <div>
		
	 </div>
 </main>