import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, Observable, of } from 'rxjs';
import { AppConfigService } from '../app-config.service';
import { AppError } from '../common/class/app-error';
import { ExternalTokenModel } from '../Model/ExternalTokenModel';
import { IExternalTokenResponseDetails } from '../Model/ExternalTokenRespone';
import { TokenModel } from '../Model/TokenModel';

@Injectable({
  providedIn: 'root'
})
export class RpostAuthenticationService {
  baseAuthAPIUrl=''
  accessTokenEndPoint='/api/v1/auth/Token'
  externalTokenEndPoint= '/api/auth/ExtToken'
  constructor(private http: HttpClient,private config: AppConfigService,private router:Router) {
    this.baseAuthAPIUrl = this.config.baseAuthApiUrlCfg;
    
   }
    //Get JWT token from RCAP API external token endpoint
    getExternalTokenAPIResponseToken(externalTokenModelObj:ExternalTokenModel)
    {
      
      let externalTokenAPIUrl=this.baseAuthAPIUrl+this.externalTokenEndPoint;
      //return this.http.post<string>(externalTokenAPIUrl,externalTokenModelObj);
      const data=JSON.stringify({Provider_Access_Token: externalTokenModelObj.provider_access_token
          ,Provider: externalTokenModelObj.provider,Client_id: externalTokenModelObj.Client_Id});
      return this.http.post<IExternalTokenResponseDetails>(externalTokenAPIUrl, data , {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      })
      .pipe(
        //catchError(this.handleError)
      );

    }

    
    apiGetById(_url: string) {
      const url = `${this.baseAuthAPIUrl}/${_url}`;
     return this.http.get(url)
     .pipe(
       catchError(this.handleError)  
     );  
    
    }

    private handleError(err: HttpErrorResponse ): Observable<AppError> {
      const appError = new AppError(err);
      const errorStatus = err.status;
      const errorStatusText = err.statusText;
      const error: any = err.error;
      let errorMsg = '';
      let msgId = '';
      
      if (error.Message !== undefined && Array.isArray(error.Message)&& err.error.Message[0]!=undefined) {
          errorMsg = err.error.Message[0].Message;
          msgId = err.error.Message[0].MessageId;
      } else {
        if (error.error_description) {
          errorMsg = error.error_description;
        } else {
          if (error.Message) {
            errorMsg = error.Message;
            msgId = (error.MessageId) ? error.MessageId : '';
          } else {
            errorMsg = errorStatusText;
          }
        }
      }
  
      const retMsg = `Error (${errorStatus}): ${errorMsg}`;
      appError.Message = retMsg;
      appError.MessageId = msgId;
  
      return of(appError);
    }

}


