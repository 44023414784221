import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QueryStringValuesModel } from '../Model/MFA/QueryStringValuesModel';
import { CommonUtilService } from '../service/common-util.service';
import { IValidateMFATokenResponseDetails } from '../Model/MFA/ValidateMFATokenResponse';
import { ErrorReason } from '../Model/ErrorReasonModel';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent {
  qs:string | undefined;
  validateTokenSnapShot:IValidateMFATokenResponseDetails | undefined;
  constructor(private route: ActivatedRoute, private commonUtilSvc:CommonUtilService
      ){
    this.route.queryParams.subscribe(params => {
      
      this.qs = params['qs'];

      
     });
     
     //this.rcapClientId=this.config.rcapClientId;
  }
  async GetQueryStringValues():Promise<QueryStringValuesModel>
{
  let queryStringValuesObj= new QueryStringValuesModel();
  let qs=this.commonUtilSvc.ConvertBase64ToString(this.qs!);
  console.log(qs);
  let emailAddress='';//this.commonUtilSvc.GetParameterByName("?EmailAddress",qs)!;
  let password=''; //this.commonUtilSvc.GetParameterByName("Pwd",qs)!;
  //let clientId= this.rcapClientId!; //this.commonUtilSvc.GetParameterByName("ClientId",qs)!;
  let sourceClientId= this.commonUtilSvc.GetParameterByName("?SourceClientId",qs)!;
  let mfaToken=this.commonUtilSvc.GetParameterByName("MFAToken",qs)!;
  let callBackUrl=this.commonUtilSvc.GetParameterByName("CallBackUrl",qs)!;
  let mfaEnabled= this.commonUtilSvc.GetParameterByName("MFAEEnabled",qs)!;
  let mfaEnabledBoolValue=(mfaEnabled=="true");
  let passwordChangeType=parseInt(this.commonUtilSvc.GetParameterByName("MFAEEnabled",qs)!);
  let loginUrl=this.commonUtilSvc.GetParameterByName("LoginUrl",qs)!;
  let lang=this.commonUtilSvc.GetParameterByName("Lang",qs)!;
  
  queryStringValuesObj.EmailAddress=emailAddress;
  queryStringValuesObj.Password=password;
  queryStringValuesObj.ClientId=''; //clientId;
  queryStringValuesObj.SourceClientId=sourceClientId;
  queryStringValuesObj.MFAToken=mfaToken;
  queryStringValuesObj.CallBackUrl=callBackUrl;
  queryStringValuesObj.MFAEEnabled=mfaEnabledBoolValue;
  queryStringValuesObj.PasswordChangeType=passwordChangeType;
  queryStringValuesObj.LoginUrl=loginUrl;
  queryStringValuesObj.Language=lang;
  return queryStringValuesObj;
  
  
}
  async ngOnInit(): Promise<void> {

    let qsResults= this.GetQueryStringValues().then( qsValues => { 
    
      
      if(this.route.snapshot.data['validateTokenDetails']!=undefined)
      {
        this.validateTokenSnapShot=this.route.snapshot.data['validateTokenDetails'];
        if(this.validateTokenSnapShot!=null && this.validateTokenSnapShot?.resultContent!=null 
          && this.validateTokenSnapShot?.resultContent!=undefined)
          {
            //this.emailAddressFromResp=this.validateTokenSnapShot?.resultContent.emailAddress;
          }
        
        
        
        if(this.validateTokenSnapShot!=null && this.validateTokenSnapShot.Message!=null 
          && this.validateTokenSnapShot.Message!=undefined )
        {
          let errorResp=this.validateTokenSnapShot.Message;
          let redirectUrl='';
          if(qsValues.LoginUrl!=null && qsValues.LoginUrl!=undefined && qsValues.LoginUrl!='')
          {
            redirectUrl=qsValues.LoginUrl;
          }
          else
          {
            redirectUrl=qsValues.CallBackUrl;
          }
          
          if(errorResp=='MFA token is invalid')
          {
            errorMsgToDisplay= ErrorReason.RESET_PWD_SESSION_EXPIRED_MSG; //'Session is expired'
          }
          setTimeout(function () {
            window.location.href=redirectUrl+'?errorReason='+errorMsgToDisplay;
          }, 3000);
          
        }
      }
      
      
      var mfaToken=qsValues.MFAToken;
      if(localStorage.getItem("Error_ValidateToken_"+mfaToken)!=null && localStorage.getItem("Error_ValidateToken_"+mfaToken)!=undefined)
      {
            
            var errorMsgToDisplay=localStorage.getItem("Error_ValidateToken_"+mfaToken)?.toString()!;
            //this.displayValidationErroMsg(errorMsgToDisplay);
            localStorage.removeItem("Error_ValidateToken_"+mfaToken);
      }
      
    });
    

  }

}
