<main class="login_wrapper my3" style="height: 100vh; background: aliceblue;">
    <div class="container">
<div class="session d-flex justify-content-center">
    <form action="" class="log-in" autocomplete="off"> 
        <a href="" class="d-flex align-items-center justify-content-center mb-3"><img src="assets/images/R1-logo.svg" alt="RPOST" style="height: 75px;"></a>
        <h3 class="custom-Multi-Factor text-center mb-3" style="font-size: 20px;">Reset Your Password</h3>
        <p class="mb-0 text-center mb-3" style="opacity: .8;">Your password is expired, please reset your password.</p>
        <div class="d-flex justify-content-center align-items-center">
        <a [routerLink]="['/reset-password']" class="rpost_blog_btn">Change My Password</a> 
    </div>    
 
      </form>
</div>
    </div>
    
</main>