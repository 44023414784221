import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class DataSharingService {
  clientIdValue='';
  private userStorage;
  
    constructor() {
        this.clientIdValue=localStorage.getItem("clienIdLS")!;
        this.userStorage = localStorage.getItem("extTokenAPIResponse_"+this.clientIdValue)!;
    }

    postCrossDomainMessage(link: any, portal = 'trackingportal') {
        
        let postURL= localStorage.getItem("callbackUrl_"+this.clientIdValue)!; //ex:tracking portal
        const iframe = document.getElementById("receiverSOS1");
        console.log(iframe);
        if (iframe == null) { return; }
        const iWindow = (iframe as HTMLIFrameElement).contentWindow!;
        const storageData = "senderSecureData";
        console.log(storageData);
        setTimeout(function () {
            iWindow.postMessage("hello", postURL);
        }, 1000);
        
    }
}
