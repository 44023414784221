import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '../app-config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonUtilService } from './common-util.service';
import { TranslateService } from '@ngx-translate/core';
import { ResetPasswordRequestModel } from '../Model/ResetPassword/ResetPasswordRequestModel';
import { Observable, catchError, of, retry } from 'rxjs';
import { AppError } from '../common/class/app-error';
import { AccessTokenRequestModel } from '../Model/ResetPassword/AccessTokenRequestModel';

@Injectable({
  providedIn: 'root'
})
export class ResetpasswordService {
  baseAuthAPIUrl: string;

  constructor(private http: HttpClient,private config: AppConfigService
    ,private router:Router,private route: ActivatedRoute
    ,private commonUtilSvc:CommonUtilService
    ,private translate: TranslateService) {
      this.baseAuthAPIUrl = this.config.baseAuthApiUrlCfg;

     }


ResetPassword(reqObj:ResetPasswordRequestModel)
  {
    let resetPwdAPIUrl=this.baseAuthAPIUrl+'/api/v1/password/Reset';
    const data=JSON.stringify({mfA_Token: reqObj.mfA_Token
      ,password:reqObj.password
      ,confirmPassword:reqObj.confirmPassword
      ,client_Id: reqObj.client_Id
      ,sourceClient_Id:reqObj.sourceClient_Id
      ,redirectURI:reqObj.redirectURI
      });
      return this.http.post(resetPwdAPIUrl, data,
        {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        })
        .pipe(catchError(this.handleError));

  }

  GetAccessToken(reqObj:AccessTokenRequestModel)
  {
    let resetPwdAPIUrl=this.baseAuthAPIUrl+'/api/v1/MFA/AccessToken';
    const data=JSON.stringify({mfA_Token: reqObj.mfA_Token
      ,sourceClient_Id:reqObj.sourceClient_Id
      ,client_Id: reqObj.client_Id
      ,redirectURI:reqObj.redirectURI
      });
      return this.http.post(resetPwdAPIUrl, data,
        {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        })
        .pipe(catchError(this.handleError));

  }

  private handleError(err: HttpErrorResponse ): Observable<AppError> {
    const appError = new AppError(err);
    const errorStatus = err.status;
    const errorStatusText = err.statusText;
    const error: any = err.error;
    let errorMsg :any;
    let msgId = '';
    
    if (error.Message !== undefined && Array.isArray(error.Message)&& err.error.Message[0]!=undefined) {
        errorMsg = err.error.Message[0].Message;
        msgId = err.error.Message[0].MessageId;
    } else {
      if (error.error_description) {
        errorMsg = error.error_description;
      } else {
        if (error.message) {
          errorMsg = error.message;
          if(error.resultContent!=null)
          {
            let errorResultContent=error.resultContent;
            errorMsg=(errorResultContent.errors[0]!=undefined)?errorResultContent.errors[0]:errorMsg;
          }
          if(error.statusCode=="401") //Unauthorized
          {
            //let callBackUrl= this.callBackUrl+"?errorReason="+errorMsg.message;
            //window.location.href= "http://localhost:63183/Login?errorReason="+errorMsg.message;
           //this.RedirectToCallBackUrl(errorMsg.message);
          }
              
          msgId = (error.MessageId) ? error.MessageId : '';
        } else {
          errorMsg = errorStatusText;
        }
      }
    }

    // const retMsg = `Error (${errorStatus}): ${errorMsg}`;
    const retMsg = `${errorMsg.message}`;
    appError.Message = retMsg;
    appError.MessageId = msgId;

    return of(appError);
  }
}
