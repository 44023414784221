export class QueryStringValuesModel
{
    EmailAddress!: string;
    Pwd!: string;
    ClientId!: string;
    SourceClientId!:string;
    MFAToken!:string;
    CallBackUrl!:string;
    LoginUrl!:string;
    MFAEEnabled!:boolean;
    PasswordChangeType:number | undefined;
    Password!: string;
    Language!:string;
}