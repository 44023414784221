<!-- <mat-toolbar color="primary">
  <a class="title" href="/">{{ title }}</a>

  <div class="toolbar-spacer"></div>

 
  <a mat-button (click)="loginPopup()" id="btnLogin">Login using Popup</a> 
  <a mat-button (click)="logout(true)" id="btnLogout" style="display:none">Logout</a>
  
  

</mat-toolbar> -->

<div  class=" container-login100">
  <app-header ></app-header>
  <div id="divProcessingCommon" class="processing text-center" style="display: flex;justify-content: center;align-items: center;width: 100%;position: relative;">
    <img src="../assets/images/orange-loader.gif"
          alt="Processing..."  style="position: absolute;top: 221px;z-index: 999; width:175px;"
       />
</div>
  <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
  <router-outlet *ngIf="!isIframe"></router-outlet>
</div>
