import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  baseAuthApiUrlCfg = environment.baseAuthAPIUrl;
  rcapClientId=environment.rcapClientId;
   constructor() { }
  
}
