import { AzureTokenResponseModel } from "../AzureTokenResponseModel";

export class GoogleTokenResponseModel extends AzureTokenResponseModel
{
   /*  accesstoken!: string;
    username!: string;
    useremail!: string;
    idtoken!:string;
    refreshtoken!:string; */



}

