export class MessageDetailsResponse{
    Status!: string;
    StatusCode!: number;
    StatusText!: string;
    
}

export class MessageResponse
{
    Message!: string;
    MessageId!: string;
}
export class ResultContent
{
    refresh_token!: string;
    refresh_expires_in!: string;
    refresh_expires!: string;
    access_token!: string;
    token_type!: string;
    expires_in!: number;
    emailaddress!: string;
    issued!: string;
    expires!: string;
    
}

export interface IExternalTokenResponseDetails
{
    status:string;
    statusCode:number;
    statusText:string;
    message:IMessageResponse;
    resultContent:IResultContent;
}

export interface IMessageResponse
{
    Message:string;
    MessageId:string;

}

export interface IResultContent
{
    refresh_token: string;
    refresh_expires_in: string;
    refresh_expires: string;
    access_token: string;
    token_type: string;
    expires_in: number;
    emailaddress: string;
    issued: string;
    expires: string;
}