import { Injectable, inject } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  ResolveFn
} from '@angular/router';
import { Observable, catchError, map, of, pipe } from 'rxjs';
import { IMFATokenResponseDetails } from '../Model/MFA/MFATokenResponse';
import { CommonService } from '../service/common/common.service';
import { MFAAuthenticationService } from '../service/mfaauthentication.service';

// @Injectable({
//   providedIn: 'root'
// })
export const EmailAuthenticationResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  //router:Router,
  mfaSvc: MFAAuthenticationService = inject(MFAAuthenticationService)
): Observable<{}> =>
    
    mfaSvc.ValidateMFATokenFromServer()
    .pipe(
      map((result: any) => {
        
        //this.router.navigate(['/errorpage',error]);
        //window.location.href="http://localhost:4200/reset-password";
        return result;
      })
      //,delay(5000)
    )
    .pipe(
        catchError((err) => {
          return of('No data' + err);
        })
      );
/*export class EmailAuthenticationResolver implements Resolve<IMFATokenResponseDetails> {
  mfaTokenRequestModelObj: any;
  constructor(private router:Router,private commonSvc:CommonService,private mfaSvc:MFAAuthenticationService
    ) {

    
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IMFATokenResponseDetails> {
    let qs = route.paramMap.get('qs')!;
    let qsPlainText=this.commonSvc.convertBase64ToString(qs);
    console.log(qs);
    let emailAddress=this.commonSvc.GetParameterByName("?EmailAddress",qsPlainText)!;
    let password= this.commonSvc.GetParameterByName("Pwd",qsPlainText)!;
    let clientId=this.commonSvc.GetParameterByName("ClientId",qsPlainText)!;
    let sourceClientId= this.commonSvc.GetParameterByName("SourceClientId",qsPlainText)!;
    let mfaToken=this.commonSvc.GetParameterByName("MFAToken",qsPlainText)!;

    this.mfaTokenRequestModelObj.emailAddress=emailAddress;
    this.mfaTokenRequestModelObj.password=password;
    this.mfaTokenRequestModelObj.clientId=clientId;
    this.mfaTokenRequestModelObj.sourceClientId=sourceClientId;
    this.mfaTokenRequestModelObj.mfaToken=mfaToken;
    return  this.mfaSvc.ValidateMFAToken(this.mfaTokenRequestModelObj)
    .pipe(
      // catchError(error => {
      //   let errorDescription=error;
      //   this.router.navigate(['/errorpage',error]);
      //     //return of(null);
      // })
    );
  
  }
}
*/