import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ThisReceiver } from '@angular/compiler';
import { ErrorReason } from '../Model/ErrorReasonModel';
import { GoogleAuthenticationService } from '../service/google-authentication.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-rpost-authentication-logout',
  templateUrl: './rpost-authentication-logout.component.html',
  styleUrls: ['./rpost-authentication-logout.component.css']
})
export class RpostAuthenticationLogoutComponent implements OnInit {
  logoutCallBakcUrl='';
  postUrl='';
  loginFailReason='';
  emailAddress='';
  ssotype='';
  /* @HostListener('window:message', ['$event'])
  onMessage(e: any) { 
    console.log(e);
    this.logoutForFailure();
   
  } */
  
  constructor(private authService: MsalService,private route: ActivatedRoute
    ,private sanitizer: DomSanitizer
    ,private router:Router
    ,private googleAutheService:GoogleAuthenticationService) {
    this.route.queryParams.subscribe( params => this.logoutCallBakcUrl=params['logoutcallbackurl']);
    this.route.queryParams.subscribe( params => this.loginFailReason=params['errorReason']);
    this.route.queryParams.subscribe(params => this.emailAddress=params['emailAddress']);
    this.route.queryParams.subscribe(params => this.ssotype=params['ssotype']);
   }

   

  ngOnInit(): void {
    var callbackUrl=this.logoutCallBakcUrl;
    var ssotype= this.ssotype;
    this.performLogoutAction(callbackUrl,ssotype);
  }

  performLogoutAction(callbackUrl:string,ssotype:string)
  {
    switch(ssotype)
    {
      case environment.azureADProvider:
           this.azureLogout(callbackUrl);
           break;
      case environment.googleProvider:
           this.googleLogout(callbackUrl);
           break;

        default:
             this.azureLogout(callbackUrl);
             break;

    }

  }
  azureLogout(callbackUrl:string)
  {
    if(this.loginFailReason!=null)
        {
            this.logoutForFailure(this.loginFailReason,callbackUrl);
        }
        else
            this.logout(callbackUrl);
  }

  logoutForFailure(loginFailReason:string,logoutCallBakcUrl:string)
  {
    let errorDesc='';
    if(loginFailReason.indexOf(ErrorReason.CLIENT_APP_PRIVILEGES_ERROR)>-1)
    {
      errorDesc=ErrorReason.CLIENT_APP_PRIVILEGES_ERROR;
    }
    else
      errorDesc=loginFailReason;
    let callbackUrl=logoutCallBakcUrl+'?errorReason='+errorDesc;
    this.logout(callbackUrl);
    
  }

  logout(logoutCallBakcUrl:string) {
    let returnUrl= '' 
    returnUrl=logoutCallBakcUrl;
    let isPopupblocker=this.detectPopupBlocker();
    let activeUserName=this.getActiveAccountUserName();
    let loggedInActiveUserName=this.getActiveAccountByUserName(this.emailAddress);
    const homeAccountId=this.authService.instance.getActiveAccount()?.homeAccountId!;
    const currentAccount = this.authService.instance.getAccountByHomeId(homeAccountId);
    //alert('activeUserName'+ activeUserName);
    //alert('loggedInUserName'+ loggedInActiveUserName);
    //alert('test');
    if(isPopupblocker)
    {
      //alert(this.isUserAlreadyLoggedin());
      
      //if(this.isUserAlreadyLoggedin())
      if(loggedInActiveUserName!=null && loggedInActiveUserName!=undefined && loggedInActiveUserName!='')
      {
        this.authService.logout({
          postLogoutRedirectUri:returnUrl,
          account:currentAccount,
          
        });
      }
      else
      {
        //window.location.href=returnUrl;
        this.authService.logout({
          postLogoutRedirectUri:returnUrl,
          account:currentAccount,
          
        });
      }
    }
    else
    { 
      //if(this.isUserAlreadyLoggedin())
      if(loggedInActiveUserName!=null && loggedInActiveUserName!=undefined && loggedInActiveUserName!='')
      {
          
        this.showLogoutPopup(returnUrl,currentAccount);
        
      }
      else
        this.showLogoutPopup(returnUrl,currentAccount);

       


    }
       
    
  }


  showLogoutPopup(returnUrl:string,currentAccount:any)
  {
    
            this.authService.logoutPopup({
      
              mainWindowRedirectUri:returnUrl,
              account:currentAccount,
                
              }).subscribe((result:any)=>
                {

                },
                error =>
                {
                      // handle error
                    var errorDesc=error.toString();
                    let errorReason=''
                  
                    if(typeof errorDesc == "string" && errorDesc.indexOf(ErrorReason.INTERACTION_IN_PROGRESS) > -1)
                    {
                      errorReason= ErrorReason.INTERACTION_IN_PROGRESS;
                      alert('Please open the logout popup');
                      /* //if (location.hash.includes("id_token")) {
                        open(location.href, '_self')!.close()
                        window.close()
                        //} */
                        //window.location.reload();
                        //return;
                    }
                    console.log(error)
                }
              ) 
            
              

          
          
  }
  detectPopupBlocker()
    {
    
    var isPopupblocker=false;
    var windowUrl = 'about:blank';
    var windowId = 'TestPopup_' + new Date().getTime();
    var windowFeatures = 'left=0%,top=0%,width=0.1%,height=0.1%';
    var windowRef = window.open(windowUrl, windowId, windowFeatures);

    if ( !windowRef )
    {
      //alert('A popup blocker was detected. Please turn it off to use this application.');
      //return false;
      $('#pnlPopupblockerMsg').css("display","block");
      isPopupblocker=true;
    }
    else
    {
      // No popup blocker was detected...
      windowRef.close();
      isPopupblocker=false;
      $('#pnlPopupblockerMsg').css("display","none");
      //document.getElementById('pageContent').style.display = 'block';
    }

    return isPopupblocker;
    }
  

  generateDataShareIframeHtmlElement(logoutCallBakcUrl:string)
   {
    const iframe = document.createElement('IFRAME');
    iframe.id = 'clientAppIframe_Logout';
    iframe.style.display = "none";
    (<HTMLIFrameElement>iframe).src = logoutCallBakcUrl; 
    document.body.appendChild(iframe);
    
   }

   isUserAlreadyLoggedin()
  {
    const homeAccountId=this.authService.instance.getActiveAccount()?.homeAccountId!;
    const currentAccount = this.authService.instance.getAccountByHomeId(homeAccountId);
    let isLoggedIn = this.authService.instance.getActiveAccount()?.username!= null;
    
    //alert('homeAccountId'+ homeAccountId);
    //alert('current username' + this.authService.instance.getActiveAccount()?.username);
    //alert('isLoggedIn' + isLoggedIn);
    return isLoggedIn;
  }

  getActiveAccountUserName()
  {
    return this.authService.instance.getActiveAccount()?.username; 
  }

  getActiveAccountByUserName(userEmailAddress:string)
  {
    //alert('userEmailAddress'+ userEmailAddress);
    let userNameFromMSAL='';
    if(userEmailAddress!=null && userEmailAddress!=undefined && userEmailAddress!='')
    {
        userNameFromMSAL= this.authService.instance.getAccountByUsername(userEmailAddress)?.username!;
        //alert('userNameFromMSAL'+userNameFromMSAL);
    }
    else
      userNameFromMSAL=this.getActiveAccountUserName()!; //for backward compatibility if emailaddress query string not there

      return userNameFromMSAL;
  }

  SignOutButtonClick()
  {
    //window.location.href=this.logoutCallBakcUrl;
    //this.logout(this.logoutCallBakcUrl);
    var callbackUrl=this.logoutCallBakcUrl;
    var ssotype= this.ssotype;
    this.performLogoutAction(callbackUrl,ssotype);
  }

  reload() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }
  

  navigateToUrl(url:string)
  {
    //this.router.navigateByUrl(url);
    this.router.navigate(['/', 'rpost-authentication-logout'], { queryParams: {logoutCallBakcUrl: this.logoutCallBakcUrl,emailAddress:this.emailAddress},queryParamsHandling: 'merge' });
  }

  googleLogout(callbackUrl:string)
  {
    this.googleAutheService.signOut(callbackUrl);
    
  }

}




    





