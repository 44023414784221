export enum ErrorReason {
  ACQUIRE_TOKEN_FAILURE='ACQUIRE_TOKEN_FAILURE', 
  AZURE_LOGIN_FAILURE='You have closed login popup, please sign in again', 
  AZURE_TOKEN_EMPTY_RESPONSE_FIRST_TIME_LOGIN='Error occured, please sign in again',//'AZURE_TOKEN_EMPTY_RESPONSE_FIRST_TIME_LOGIN', 
  AZURE_TOKEN_EMPTY_RESPONSE_ACQUIRE_TOKEN_POPUP='AZURE_TOKEN_EMPTY_RESPONSE_ACQUIRE_TOKEN_POPUP', 
  AZURE_TOKEN_EMPTY_RESPONSE_ACQUIRE_TOKEN_SILENT='AZURE_TOKEN_EMPTY_RESPONSE_ACQUIRE_TOKEN_SILENT',
  USER_CLOSED_LOGIN_POPUP='USER_CLOSED_LOGIN_POPUP',
  ACQUIRE_TOKEN_POPUP_FAILURE='ACQUIRE_TOKEN_POPUP_FAILURE',
  LOGIN_POPUP_FAILURE='LOGIN_POPUP_FAILURE' ,
  MSAL_GUARD_CONFIG_AUTH_REQUEST_LOGIN_POPUP_FAILURE='AUTH_REQUEST_LOGIN_POPUP_FAILURE',
  UNKNOWN_ERROR_OCCURED='UNKNOWN_ERROR_OCCURED',
  COULD_NOT_ABLE_TO_SEND_MESSAGE='COULD_NOT_ABLE_TO_SEND_MESSAGE',
  AZURE_TOKEN_EMPTY_RESPONSE_BEFORE_SENDING_MESSAGE='AZURE_TOKEN_EMPTY_RESPONSE_BEFORE_SENDING_MESSAGE',
  USER_CANCELLED='user_cancelled' ,
  CONSENT_REQUIRED='consent_required',
  INTERACATION_REQUIRED='interaction_required',
  LOGIN_REQUIRED='login_required',
  CLIENT_APP_PRIVILEGES_ERROR='USER_DO_NOT_HAVE_PRIVILEGES',
  USER_CANCELLED_AT_LOGIN='user_cancelled_from_login',
  USER_CANCELLED_FROM_UNAUTHORIZED_AT_LOGIN_POPUP='Error occured at login popup, please sign in again',
  USER_CANCELLED_CURRENT_REQUEST='user_cancelled_current_request_from_SSO_system',
  INTERACTION_IN_PROGRESS='interaction_in_progress',
  GOOGLE_LOGIN_FAILED='Error Occured,Please login using Google SSO login',
  MESSAGE_TO_POST_EMPTY_RESPONSE='Response is empty',
  MFA_VALIDATE_TOKEN_INVALID_EMAIL='Invalid Email Address',
  INVALID_MFA_TOKEN_ERROR_MSG_KEY='RCAP_MFA_Invalid_Token',
  INVALID_MFA_TOKEN_ERROR_MSG='Invalid MFA Token',
  INVALID_MFA_TOKEN_ERROR_MESG1='MFA session has expired',
  MFA_NOT_ENABLED_PWD_CHANGE_NOT_REQUIRED='MFA not enabled  and Password change is not required',
  ACCESS_TOKEN_IS_EMPTY='Error occured while generating access token',
  MESSAGE_TO_POST_IS_EMPTY='Erro occured: Message is empty',
  RESET_PWD_SCREEN_RESPONSE_IS_EMPTY='Error occured while getting access token: response is null',
  PAGE_REFRESH_IS_NOT_ALLOWED='Due to the page refresh/back button click, you have been redirected to the login page.',
  RESET_PWD_INVALID_ATTEMPTS='The password reset attempts are unsuccessful. Please try again.',
  RESET_PWD_SESSION_EXPIRED_MSG='Password reset session has expired.'

  }