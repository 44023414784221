import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TranslateLoader} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {shareReplay} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TranslationLoaderService implements TranslateLoader {
  cache$: Observable<any> = null!;
  cachedLang: string = null!;
  prefix = '../../assets/i18n/';
  suffix = '.json';
  constructor(private http: HttpClient) {}
  public getTranslation(lang: string): Observable<any> {
    if (!this.cache$ || this.cachedLang !== lang) {
      this.cache$ = this.http.get(`${this.prefix}${lang}${this.suffix}`).pipe(shareReplay(1));
      this.cachedLang = lang;
    }
    return this.cache$;
  }
  public  use(lang: string): Observable<any> {
    if (!this.cache$ || this.cachedLang !== lang) {
      this.cache$ = this.http.get(`${this.prefix}${lang}${this.suffix}`).pipe(shareReplay(1));
      this.cachedLang = lang;
    }
    return this.cache$;
  }
}
