<header  class="header">
        
    <div class="brand-header d-flex justify-content-between align-items-center">
       
        <div class="header-app-logo">
            <!-- <img id="hdrLeftSideLogo" src="../assets/images/LatestLogo.png" alt="RMail Web"> -->
            <img  [src]="hdrLeftSideLogoImgPath" alt="RCAP">
        </div>
    
      
        <div class="header-co-logo d-flex align-items-center">
        
        <img src="../assets/images/rpost.gif" alt="RPost">
        </div>

     
</div>


</header>