import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '../app-config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MFATokenRequestModel } from '../Model/MFA/MFATokenRequestModel';
import { IMFATokenResponseDetails } from '../Model/MFA/MFATokenResponse';
import { Observable, catchError, map, of, pipe, retry, throwError } from 'rxjs';
import { VerifyAccessCodeModel } from '../Model/MFA/VerifyAccessCodeModel';
import { IVerifyAccessCodeResponseDetails } from '../Model/MFA/VerifyAccessCodeResponse';
import { CommonService } from './common/common.service';
import { AppError } from '../common/class/app-error';
import { IValidateMFATokenResponseDetails } from '../Model/MFA/ValidateMFATokenResponse';
import { IResendOTPResponseDetails } from '../Model/MFA/ResendOTPResponseModel';
import { CommonUtilService } from './common-util.service';
import { VerifyOTPRequestModel } from '../Model/MFA/VerifyOTPRequestModel';
import { IVerifyOTPResponseDetails } from '../Model/MFA/VerifyOTPResponseModel';
import { TranslateService } from '@ngx-translate/core';
import { ErrorReason } from '../Model/ErrorReasonModel';

@Injectable({
  providedIn: 'root'
})
export class MFAAuthenticationService {
  baseAuthAPIUrl: any;
  qs: any;
  mfaTokenRequestModelObj: any;
  callBackUrl:any;
  qsBase64: any;
  mfaToken:any;
  sourceClientId:any;
  appLang: any;
  rcapClientId:any;
  loginUrl:any;
  constructor(private http: HttpClient,private config: AppConfigService
    ,private router:Router,private route: ActivatedRoute
    ,private commonUtilSvc:CommonUtilService
    ,private translate: TranslateService) {
    this.baseAuthAPIUrl = this.config.baseAuthApiUrlCfg;
    this.rcapClientId=this.config.rcapClientId;
    
    
   }

  

  ValidateMFAToken(reqObj:MFATokenRequestModel):Observable<IMFATokenResponseDetails> 
  {
      let mfaTokenAPIUrl=this.baseAuthAPIUrl+'/api/v1/MFA/Token/Validate';
      const data=JSON.stringify({emailAddress: reqObj.emailAddress
          ,password: reqObj.password,client_id: reqObj.clientId,sourceClient_id:reqObj.sourceClientId
          ,mfaToken:reqObj.mfaToken});
      return this.http.post<IMFATokenResponseDetails>(mfaTokenAPIUrl, data , {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      })
      .pipe(
        map((result: any) => {
          
          
          return result;
        })
        
      ); 
      

  }
  SetLanguage(lang:string)
  {
    this.appLang=lang;
              if(this.appLang!=null)
                this.translate.setDefaultLang(this.appLang);
              else
                this.translate.setDefaultLang('en-us');
  }
  ValidateMFATokenFromServer()
  {
    this.qsBase64=this.commonUtilSvc.GetParameterByName("?qs",window.location.href)!;
    
    let qsPlainText=this.commonUtilSvc.ConvertBase64ToString(this.qsBase64);
    console.log(qsPlainText);
    let emailAddress='';
    let password= ''; 
    let clientId= this.rcapClientId; 
    this.sourceClientId= this.commonUtilSvc.GetParameterByName("?SourceClientId",qsPlainText)!;
    this.mfaToken=this.commonUtilSvc.GetParameterByName("MFAToken",qsPlainText)!;
    localStorage.setItem("MFAToken",this.mfaToken);
    this.callBackUrl=this.commonUtilSvc.GetParameterByName("CallBackUrl",qsPlainText)!;

    let loginUrl=this.commonUtilSvc.GetParameterByName("LoginUrl",qsPlainText)!;
    
    localStorage.setItem("LoginUrl_"+this.mfaToken,loginUrl);
    localStorage.setItem("LoginUrl_Error",loginUrl);
    let redirectUri=this.callBackUrl;//Todo: Get value based on OTPVerify/PWdChange flags
    localStorage.setItem("RedirectURI_"+this.mfaToken,redirectUri);
    localStorage.setItem("SourceClientId_"+this.mfaToken,this.sourceClientId);
    localStorage.setItem("ClientId_"+ this.mfaToken,clientId);
    let fromUrl=this.commonUtilSvc.GetParameterByName("From",qsPlainText);
    
    let mfaTokenAPIUrl=this.baseAuthAPIUrl+'/api/v1/MFA/Token/Validate';
      
      const data=JSON.stringify({mfA_Token:this.mfaToken,RedirectURI:redirectUri,sourceClient_Id:this.sourceClientId
                                  ,client_Id:clientId});
      return this.http.post<IMFATokenResponseDetails>(mfaTokenAPIUrl, data , {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      })
      .pipe(
        map((result: any) => {
          
          const response =result as unknown as IValidateMFATokenResponseDetails;
          if(response!=null && response.resultContent!=null)
          {
              let resultOutput=response.resultContent;
              
              localStorage.setItem("mfaToken",this.mfaToken);
              
              
               if(fromUrl!=null && fromUrl!='' && fromUrl!=undefined && fromUrl=='RPL') //Request came from WebAPI Email link,navigate to Resetpassword screen
               {
                  console.log("Request came from Reset Password Email Link");
                  this.router.navigate(['/reset-password']);
               } 
               else if(resultOutput.mfaEnabled && resultOutput.accessCodeEmailed)
               {
                //redirect to MFA Login screen
                                
                
               }
               else if(!resultOutput.mfaEnabled && resultOutput.passwordChange==0)
               {
                // redirect to callback back because MFA is not enabled and PAsswordChange is also 0
                  let errorReason= ErrorReason.MFA_NOT_ENABLED_PWD_CHANGE_NOT_REQUIRED;//'MFA not enabled  and PAssword change is not required';
                  this.RedirectToCallBackUrl(errorReason,loginUrl);
               }
               else if(!resultOutput.mfaEnabled && resultOutput.passwordChange==1 && window.location.href.indexOf("resetpassword")==-1)
               {
                  //redirect to Change password screen 
                  
                  var numOfDaysToExpire=resultOutput.passwordExpiresIn; //To do we need to get from response
                  
                  this.router.navigate(['/reset-password-reminder'],{queryParams: {qs: numOfDaysToExpire }});
               }
               else if(!resultOutput.mfaEnabled && resultOutput.passwordChange==1 && window.location.href.indexOf("resetpassword")!=-1)
               {
                  //redirect to reset password screen 
                  
                  this.router.navigate(['/reset-password']);
               }
               else if(!resultOutput.mfaEnabled && resultOutput.passwordChange==-1)
               {
                //redirect to password expiry screen
                
                this.router.navigate(['/password-expired']);
               }

          }
          
          return result;
        })
        
      )
      .pipe(catchError(this.handleError));
      
  }
  
  ReloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
}
  ReSendOTP(mfaToken:string,sourceClientId:string,clientId:string,redirectUri:string) : Observable<any>
  {
    let resendOTPAPIUrl=this.baseAuthAPIUrl+'/api/v1/MFA/ReSendOTP';
    const data=JSON.stringify({MFA_Token:mfaToken
      ,SourceClient_Id:sourceClientId
      ,Client_Id:clientId
      ,RedirectURI: redirectUri
      });
      return this.http.post(resendOTPAPIUrl, data,
        {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        })
        .pipe(catchError(this.handleError));
        
  }
  private extractData(res: any) {
    let body = res;
    return body;
  }
  private handleErrorObservable(error: any) {
    console.error(error.message || error);
    return throwError(error);
  } 

  VerifyOTP(data: VerifyOTPRequestModel): Observable<any> {
    let verifyOTPAPIUrl=this.baseAuthAPIUrl+'/api/v1/MFA/VerifyOTP'
    
    return this.http.post(verifyOTPAPIUrl, data,
    {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    })
    .pipe(catchError(this.handleError));
    
    
  }

  
  private handleError(err: HttpErrorResponse ): Observable<AppError> {
    const appError = new AppError(err);
    const errorStatus = err.status;
    const errorStatusText = err.statusText;
    const error: any = err.error;
    let errorMsg :any;
    let msgId = '';
    
    if (error.Message !== undefined && Array.isArray(error.Message)&& err.error.Message[0]!=undefined) {
        errorMsg = err.error.Message[0].Message;
        msgId = err.error.Message[0].MessageId;
    } else {
      if (error.error_description) {
        errorMsg = error.error_description;
      } else {
        if (error.message) {
          errorMsg = error.message;
          if(error.resultContent!=null)
          {
            let errorResultContent=error.resultContent;
            errorMsg=(errorResultContent.errors[0]!=undefined)?errorResultContent.errors[0]:errorMsg;
          }
          if(error.statusCode=="401") //Unauthorized
          {
            
          }
              
          msgId = (error.MessageId) ? error.MessageId : '';
        } else {
          errorMsg = errorStatusText;
        }
      }
    }

    
    const retMsg = `${errorMsg.message}`;
    appError.Message = retMsg;
    appError.MessageId = msgId;

    return of(appError);
  }

  RedirectToCallBackUrl(errorMesg:any,callBackUrl:string)
  {
    
    callBackUrl= callBackUrl+"?errorReason="+errorMesg;
   window.location.href= callBackUrl;
  }
}

