import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  timeStamp :string=new Date().getTime().toString();
  hdrLeftSideLogoImgPath="../assets/images/LatestLogo.png?t="+this.timeStamp;
  constructor() { }

  ngOnInit(): void {
    /* let hdrLeftSideLogoImgPath=$("#hdrLeftSideLogo").attr('src')!;
    hdrLeftSideLogoImgPath+='?t=' + new Date().getTime();
    //alert(hdrLeftSideLogoImgPath);
    $("#hdrLeftSideLogo").attr("src",hdrLeftSideLogoImgPath); */

  }

}
